.container {
  display: flex;
  flex-direction: column;
}
.carts-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.carts-products img {
  width: 30%;
  border-radius: 50px;
}

.react-datepicker-wrapper input {
  height: 52px;
}