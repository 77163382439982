  .custom-file-input-label {
    cursor: pointer;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s;
  }

  .custom-file-input {
    opacity: 0;
    position: absolute;
    z-index: 2;
  }

  .custom-file-input-label:hover {
    background-color: #0056b3;
  }

  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px 20px 150px; /* Updated padding to include space for uploaded images */
    text-align: center;
    cursor: pointer;
  }

  .dropzone p {
    margin: 0;
    font-size: 16px;
    color: #555555;
  }

  .uploaded-image {
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;
  }

  .uploaded-image img {
    max-width: 150px;
    max-height: 150px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
  }

  .uploaded-image img:hover {
    transform: scale(1.1);
  }

  /* Updated styles for the close icon */
  .uploaded-image .MuiIconButton-root {
    position: absolute;
    top: 5px; /* Adjust the top position */
    right: 5px; /* Adjust the right position */
    cursor: pointer;
  }

  .uploaded-image .MuiSvgIcon-root {
    font-size: 18px; /* Adjust the font size to make it smaller */
  }

  .MuiDialogTitle-root {
    background-color: #007bff;
    color: #fff;
  }

  .MuiDialogActions-root {
    padding: 16px;
  }

  .MuiDialogContent-root {
    padding: 16px;
  }

  .card {
    padding: 10px;
    box-shadow: 0 0 5px #ffdfdf;
    border-radius: 5px;
    overflow: hidden;
  }

  .card .top {
    text-align: center;
  }

  .card p {
    font-weight: bold;
    color: #0086fe;
  }

  .card button {
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #0086fe;
  }

  .card .drag-area {
    height: 150px;
    border-radius: 5px;
    border: 1px dashed #0086fe;
    color: #0086fe;
    background: #f4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
  }

  .card .drag-area .visible {
    font-size: 18px;
  }

  .card .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
  }

  .card .select:hover {
    opacity: 0.6;
  }

  .card .container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    gap: 20px; /* Adjust the gap value to reduce spacing between images */
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
  }

  .card .container .image {
    width: 75px;
    margin-right: 5px; /* Adjust the margin to reduce the gap between images */
    height: 75px;
    position: relative;
    margin-bottom: 8px;
  }

  /* Styles for Images */
  .card .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  /* Styles for Videos */
  .card .container .image video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card .container .image .delete {
    position: absolute;
    top: 5px;
    left: 5px; /* Adjust the left position to place it in the upper-left corner */
    z-index: 999;
    color: #0086fe;
    cursor: pointer; /* Add cursor style to indicate it's clickable */
  }

  .card input,
  .card .drag-area .on-drop,
  .card .drag-area.dragover .visible {
    display: none;
  }
  .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* Adjust the padding-bottom to make the video larger */
    position: relative;
    overflow: hidden;
  }

  .video-container video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image {
    position: relative;
  }

  /* Add this to your existing CSS */
.thumbnail {
  position: relative;
  cursor: pointer;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
  
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); */
}
.image-container {
  position: relative;
  display: inline-block;
}

.image {
  position: relative;
}

.delete {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 999;
  color: #0086fe;
  cursor: pointer;
}
