body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4; /* Choose a background color */
}

.login-form {
  text-align: center;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* White background for the form */
  font-size: 20px;
}

.company-name {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #FDDCCD; /* Company name color */
}

.form-control {
  width: calc(100% - 24px);
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #333; /* Text color for input fields */
}

/* Style for placeholder text in input fields */
.form-control::placeholder {
  color: #888; /* Placeholder text color */
}

.btn-primary {
  background-color: #FCDBCC;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 14px 20px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  width: 100%;
}

.btn-primary:hover {
  background-color: #F9C4B3;
}

.text-danger {
  color: red;
  margin-top: 10px;
}

.mt-3 {
  margin-top: 1.5rem;
}
