.container {
  margin: 20px;

  .MuiBox-root {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .MuiFormControl-root {
    width: 200px;
  }
}

.orders-products {
  display: flex;
  flex-direction: column;
  gap: 5px;

  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
}

.orders-products img {
  border-radius: 10px;
  width: 200px;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.filter-set {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.filter-form-control {
  width: 200px;
}

.filter-form-control InputLabel,
.filter-form-control Select {
  height: 100%;
}

.filter-form-control Input {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid grey;
  border-radius: 4px;
  transition: border-color 0.3s, background-color 0.3s;
  outline: none;
  background-color: transparent;
}

.datepicker-custom-style {
  width: 100%;
  height: 100%;
}

/* Light mode button styles */
.actions-section {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.actions-container {
  display: flex;
  gap: 1rem;
}

.lookup-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

th,
td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
  font-size: 1rem;
}

th {
  font-weight: bold;
  font-size: 1.1rem;
}

[data-theme="dark"] .lookup-table th,
[data-theme="dark"] .lookup-table td {
  background-color: #333;
  color: #fff;
}

[data-theme="dark"] .lookup-table th {
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}

.custom-snackbar {
  font-size: 20px; /* Increase font size */
  padding: 20px; /* Increase padding */
}

.MuiAlert-message {
  font-size: 1.5rem !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid gold; /* Change border-left color to gold */
  border-radius: 50%;
  width: 40px; /* Increase width to make it bigger */
  height: 40px; /* Increase height to make it bigger */
  animation: rotate 1s linear infinite;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
}
