/* Container styling */
.sms-container {
  background-color: var(--background-default);
  color: var(--neutral-main);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
}

.sms-container h2 {
  font-size: 1.8rem;
  color: var(--primary-main);
  margin-bottom: 16px;
  font-family: "Inter", sans-serif;
}

/* Form Control Styling */
.MuiFormControl-root {
  width: 100%;
  margin-top: 10px;
}

/* Label Styling */
.MuiInputLabel-root {
  color: var(--primary-light);
}

/* Input and Select Styling */
.MuiOutlinedInput-root,
.MuiSelect-root {
  background-color: var(--background-alt);
  color: var(--neutral-main);
  border-radius: 4px;
}

.MuiMenuItem-root {
  color: var(--neutral-main);
}

/* Checkbox Styling */
.MuiCheckbox-root {
  color: var(--secondary-main);
}

/* Radio Group Styling */
.MuiRadio-root {
  color: var(--primary-main);
}

.radio-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

/* Textarea Styling */
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid var(--neutral-main);
  background-color: var(--background-alt);
  color: var(--neutral-main);
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

/* Send Button Styling */
.send-btn {
  background-color: var(--secondary-main);
  color: var(--background-default);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 16px;
}

.send-btn:hover {
  background-color: var(--secondary-light);
}

/* Loader container styling */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-default);
}
